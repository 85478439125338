<template>
  <CRow>
    
    <CCol col="12" lg="12">
      <CCard>
        <CCardHeader>
          <strong>Preço</strong>
        </CCardHeader>
        <CCardBody >
          <CForm @submit.prevent="updatePrice" id="form">
            <CRow>
              <CCol col=12>
                <CAlert
                  :show.sync="dismissCountDown"
                  closeButton
                  color="danger"
                  fade
                >
                  {{errorMsg}}
                </CAlert>
              </CCol>
              <CCol col=6>
                <CInput
                  label="Dias inicial"
                  v-model="initalDate"
                  :value="this.initalDate"
                  bottom
                  type="number"
                  required
                />
              </CCol>

              <CCol col=6>
                <CInput
                  label="Dias final"
                  :value="this.finalDate"
                  v-model="finalDate"
                  bottom
                  type="number"
                  required
                />
              </CCol>

              <CCol col=6>
                <CInput
                  label="Preço MOVE Europa"
                  :value="this.moveEurope"
                  v-model="moveEurope"
                  bottom
                  type="number"
                  step="0.01"
                  required
                />
              </CCol>

              <CCol col=6>
                <CInput
                  label="Preço MOVE Resto do Mundo"
                  :value="this.moveWorld"
                  v-model="moveWorld"
                  bottom
                  type="number"
                  step="0.01"
                  required
                />
              </CCol>

              <CCol col=6>
                <CInput
                  label="Preço MOVE Plus Europa"
                  :value="this.movePlusEurope"
                  v-model="movePlusEurope"
                  bottom
                  type="number"
                  step="0.01"
                  required
                />
              </CCol>

              <CCol col=6>
                <CInput
                  label="Preço MOVE Plus Resto do Mundo"
                  :value="this.movePlusWorld"
                  v-model="movePlusWorld"
                  bottom
                  type="number"
                  step="0.01"
                  required
                />
              </CCol>

              <CCol col=6>
                <CInput
                  label="Preço AP + RC MOVE Europa"
                  :value="this.aprcMoveEurope"
                  v-model="aprcMoveEurope"
                  bottom
                  type="number"
                  step="0.01"
                  required
                />
              </CCol> 


              <CCol col=6>
                <CInput
                  label="Preço AP + RC MOVE Resto do Mundo"
                  :value="this.aprcMoveWorld"
                  v-model="aprcMoveWorld"
                  bottom
                  type="number"
                  step="0.01"
                  required
                />
              </CCol>

               <CCol col=6>
                <CInput
                  label="Preço AP + RC MOVE Plus Europa"
                  :value="this.aprcMovePlusEurope"
                  v-model="aprcMovePlusEurope"
                  bottom
                  type="number"
                  step="0.01"
                  required
                />
              </CCol> 


              <CCol col=6>
                <CInput
                  label="Preço AP + RC MOVE Plus Resto do Mundo"
                  :value="this.aprcMovePlusWorld"
                  v-model="aprcMovePlusWorld"
                  bottom
                  type="number"
                  step="0.01"
                  required
                />
              </CCol>

              <CCol col=6>
                <CInput
                  label="Preço Covid MOVE Europa"
                  :value="this.covidMoveEurope"
                  v-model="covidMoveEurope"
                  bottom
                  type="number"
                  step="0.01"
                  required
                />
              </CCol>

              <CCol col=6>
                <CInput
                  label="Preço Covid MOVE Resto do Mundo"
                  :value="this.covidMoveWorld"
                  v-model="covidMoveWorld"
                  bottom
                  type="number"
                  step="0.01"
                  required
                />
              </CCol>

              <CCol col=6>
                <CInput
                  label="Preço Covid MOVE Plus Europa"
                  :value="this.covidMovePlusEurope"
                  v-model="covidMovePlusEurope"
                  bottom
                  type="number"
                  step="0.01"
                  required
                />
              </CCol>

              <CCol col=6>
                <CInput
                  label="Preço Covid MOVE Plus Resto do Mundo"
                  :value="this.covidMovePlusWorld"
                  v-model="covidMovePlusWorld"
                  bottom
                  type="number"
                  step="0.01"
                  required
                />
              </CCol>
              
              <CCol col=12 class="text-center">
                <CButton size="sm" color="warning" @click="goBack()" style="margin-right: 10px;">Back</CButton>
                <CButton type="submit" size="sm" color="success">Save</CButton>
              </CCol>
            </CRow>
            
              
          </CForm>
        </CCardBody>
        
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import Prices from "@/services/PriceDataService";
export default {
  name: 'Price',
  data: () => {
    return {
      id: '',
      dismissCountDown: 0,
      errorMsg: '',
      initalDate: '',
      finalDate: '',
      moveEurope: '',
      moveWorld: '',
      movePlusEurope: '',
      movePlusWorld: '',
      personalAccidents: '',
      aprcMoveEurope: '',
      aprcMoveWorld: '',
      aprcMovePlusEurope: '',
      aprcMovePlusWorld: '',
      covid: '',
      civilResponsability: '',
      covidMoveEurope: '',
      covidMoveWorld: '',
      covidMovePlusEurope: '',
      covidMovePlusWorld: ''
    }
  },
  mounted () {
    this.id = this.$route.params.id
    this.getPriceData();
  },
  methods: {
    updatePrice(){
      Prices.updatePrice(
        this.id, 
        this.initalDate, 
        this.finalDate, 
        this.moveEurope, 
        this.moveWorld, 
        this.movePlusEurope, 
        this.movePlusWorld,
        this.personalAccidents,
        this.civilResponsability,
        this.aprcMoveEurope,
        this.aprcMoveWorld,
        this.aprcMovePlusEurope,
        this.aprcMovePlusWorld,
        this.covid,
        this.covidMoveEurope,
        this.covidMoveWorld,
        this.covidMovePlusEurope,
        this.covidMovePlusWorld
      )
      .then(response => {
        if(response.data == 'ok'){
          let message = {
            status: 'success',
            text: '<strong>Preços atualizados</strong>'
          }
          EventBus.$emit('showMessage', message);
          this.$router.push('/prices');
        }else{
          this.errorMsg = response.data.message;
          this.dismissCountDown = '10';
        }
        return false
      })
      .catch(e => {
        alert(e);
      });
      return false
    },
    goBack() {
      this.$router.push('/prices');
    },
    deletePrice(id){
      Prices.delete(id).then(response => {
        if(response.data =='ok'){
          let message = {
            status: 'success',
            text: '<strong>Preços removidos</strong>'
          }
          EventBus.$emit('showMessage', message); 
          this.$router.push('/prices');
        }
      })
      .catch(e => {
        alert(e);
      });
    },
    getPriceData(){
      Prices.get(this.id)
      .then(response => {
        this.initalDate = response.data.initial_day_interval,
        this.finalDate = response.data.final_day_interval,
        this.moveEurope = response.data.move_europe,
        this.moveWorld = response.data.move_world,
        this.movePlusEurope = response.data.move_plus_europe,
        this.movePlusWorld = response.data.move_plus_world,
        this.personalAccidents = response.data.personal_accidents,
        this.civilResponsability = response.data.civil_responsability,
        this.aprcMoveEurope = response.data.aprc_move_europe,
        this.aprcMoveWorld = response.data.aprc_move_world,
        this.aprcMovePlusEurope = response.data.aprc_move_plus_europe,
        this.aprcMovePlusWorld = response.data.aprc_move_plus_world,
        this.covid = response.data.covid,
        this.covidMoveEurope = response.data.covid_move_europe,
        this.covidMoveWorld = response.data.covid_move_world,
        this.covidMovePlusEurope = response.data.covid_move_plus_europe,
        this.covidMovePlusWorld = response.data.covid_move_plus_world
      })
      .catch(e => {
          alert(e);
        });
    }

  },

  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    showAdminBoard() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes('ROLE_ADMIN');
      }

      return false;
    },
    showModeratorBoard() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes('ROLE_MODERATOR');
      }

      return false;
    },
    showAdminOrModeratorBoard() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes('ROLE_MODERATOR') || this.currentUser.roles.includes('ROLE_ADMIN');
      }

      return false;
    },
  }
}
</script>
